  /* Divers */
  .nav-min-height {
    min-height: 71px;
  }
  .toggler-min-height {
    min-height: 55px;
  }
  .mt-main {  /* Margin top du main = hauteur header*/
    margin-top: 71px;
  }

.h-0 {
  height: 0 !important;
}

.overflow-center {
  margin-left: -100%;
  margin-right: -100%;
}

.mb-6 {
  margin-bottom: 5rem;
}

.mt-25 {
  margin-top: 25vh;
}

.mt-2rem {
  margin-top: 2rem;
}

.z-1000 {
  z-index: 1000;
}

.opacity-40 {
  opacity: 0.4 !important;
}

.text-shadow {
  text-shadow: 2px 2px 2px #212529;
}

.scroll-top {
  display: none;
}

.link-hover-underline {
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
}

.posts-links { /* couleur primaire ou secondaire (voire une variante) */
  .page-link {
    color: #b0b0b1; /* couleur générique */
    &:hover {
      color: #c67439;
      background-color: #f8f9fa; /* couleur générique */
    }
    &:focus {
      color: #c67439;
      background-color: #f8f9fa; /* couleur générique */
      box-shadow: none;
    }
  }
 
  .page-link.active {
    color: #fff;
    background-color: #c67439;
    border-color: #c67439;
  }
 
  .active {
    >.page-link {
      color: #fff;
      background-color: #c67439;
      border-color: #c67439;
    }
  }
}

.dot-separator{
  border: 0;
  max-width: 100%;
  background-position: 50%;
  box-sizing: border-box;
  position: relative;
  opacity: 1;
}
 
.dot-separator::before {
  content: '\F287';
  font-family: bootstrap-icons;
  display: block;
  text-align: center;
  color: #cb824d;
  font-size: 12px;
}
 
.dot-separator::after{
  content:'';
  border-width: 0px 0 4px;
  $color: #cb824d;
  color: $color;
  border-image: linear-gradient(
    90deg,
    rgba($color, 0),
    rgba($color, 1) 50%,
    rgba($color, 0) 100%) 0 0 100%;
  border-style: solid;
  position: absolute;
  bottom: 7px;
  left: calc(50% - 4rem);
  width: 8rem;
}

.main-bg {
  .section-bg {
    &:nth-child(2n+1) {
      background-color: #fff !important;
    }
  }
}

.section-min-height {
  min-height: 620px;
}

  /* Navbar */
.logo-size {
  max-height: 45px;
}

  /* Carrousel */
.banner-title {
  font-weight: 600;
}

.banner-text {
  font-size: calc(1rem + 0.5vw);
  font-weight: 600;
  line-height: 1.2;
}

.border-double-primary {
  border: 10px double #d19061;
}

.border-double-secondary {
  border: 10px double #7e9581;
}

.banner-overlay {
  position: absolute;
  top: 0;
  left: 50%;
  width: 1920px;
  height: 100%;
  margin-left: -960px;
  background-color: #212529;
  opacity: 0.07;
}

.img-carousel-mobile  { 
  height: 343px !important;
  width: auto !important;
}

  /* Section Présentation */
.about-quote {
  &::before, &::after {
    position: absolute;
    font-family: bootstrap-icons !important;
    content: '\F6B0';
    font-size: 3rem;
    color: #b9c6bb;    /* Voir couleur secondaire */
  }
}
  
.about-quote::before {
  top: 0;
  left: 0;
}
  
.about-quote::after {
  bottom: 0;
  right: 0;
  transform: rotate(180deg);
}

[data-aos="about-animation"] {
  opacity: 0;
  transform: translateY(100px);
  transition-property: transform, opacity;
  &.aos-animate {
    opacity: 1;
    transform: translateY(0);
  }
}

.about-height {
  min-height: 350px;
}

.section-bg {
  .bg-color-service-block {
    background: #fff;
  }
}

.main-bg {
  .section-bg {
    &:nth-child(2n+1) {
      .bg-color-service-block {
        background: #f7f8f7;
      }
    }
  }
}

.intro-height {
  min-height: 100px; 
}
  
/* Section agrément */
.approval-logo-size {
  max-height: 120px;
}

/* Section Valeurs */
.value-image {
  max-width: 276px;
  margin: auto;
}

.value-description {
  padding: 0 1rem;
}

/* Section Avis */

.owl-dots {
  text-align: center;
}

.section-bg {
  .owl-carousel {
    button.owl-dot {
      height: 1.5rem;
      width: 1.5rem;
      border-radius: 5px;
      margin: 0 5px;
      background: #fff;
      &.active {
        background: #c67439 !important;
      }
    }
  }
}
 
.main-bg {
  .section-bg {
    &:nth-child(2n+1) {
      .owl-carousel {
        button.owl-dot {
          background: #f7f8f7;
        }
      }
    }
  }
}

.review-link {
  margin-right: 3rem;
}

.testimonial {
  margin: 0 16px 40px;
}

.testimonial .testimonial-content {
  padding: 30px 25px;
  margin-bottom: 35px;
  background: #fff;
  border: 1px solid #f0f0f0;
  border-radius: 10px;
  height: 275px;
}

.testimonial .testimonial-description {
  font-style: italic;
}

.testimonial .testimonial-description p {
  position: relative;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  margin: 0.25em 0;
  padding: 5px 20px 5px 48px;
  line-height: 1.45;
}

.testimonial .testimonial-description p:before {
  content: "\201c";
  display: block;
  padding-left: 10px;
  font-family: "Georgia";
  font-size: 90px;
  color: #b9c6bb;   /* muted */
  position: absolute;
  top: -30px;
  left: -18px;
}

.testimonial .testimonial-stars {
  text-align: center;
}

.testimonial .testimonial-name {
  font-size: 1.2rem;
  font-weight: 500;
  text-align: center;
}

.google-stars {
  font-size: 1.2rem;
  color: #FFCD03;
}

.trustpilot-stars {
  font-size: 1.2rem;
  color: #21A56E;
}

.align-stars {
  .google-stars {
    vertical-align: middle !important;
  }

  .trustpilot-stars {
    vertical-align: middle !important;
  }
}

.review {
  border: 1px solid #f0f0f0;
  border-radius: 10px;
}

.review-img {
  border-radius: 10px;
}

.position-stars {
  position: absolute;
  top: 33px;
  left: 15px;
}

/* Section Blog */
.btn.btn-filter {
  color: #d19061;
  background-color: #fff;
  border-color: #d19061;
  &:hover {
      color: #d19061;
      border-color: #d19061;
  }
  &:focus {
      color: #d19061;
      border-color: #d19061;
  }
  &:active {
      color: #d19061;
      border-color: #d19061;
  }
}
.btn.btn-filter.active {
  color: #fff;
  background-color: #d19061;
  border-color: #d19061;
}
[data-aos="post-comment-animation"] {
  opacity: 0;
  transform: translateY(100px);
  transition-property: transform, opacity;
  &.aos-animate {
    opacity: 1;
    transform: translateY(0);
  }
}
.post-comment {
  position: relative;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.post-comment.promoted {
  -webkit-line-clamp: 6;
}

.post-view {
  overflow: hidden;
  position: relative;
  img {
    transition: all 0.2s linear;
  }
  &:hover {
    img {
      transform: scale(1.1);
    }
  }
}

.post {
  a {
   color: #c67439;
   text-decoration: none;
    &:hover {
      color:#d19061;
    }
  }
 
  img[style="float: left;"] {
    margin-right: 1rem;
  }
 
  img[style="float: right;"] {
    margin-left: 1rem;
  }
 
  img {
    max-width: 100%;
    height: auto;
    margin-top: 1rem; margin-bottom: 0.5rem;
    cursor: pointer;
  }
}

.social-buttons {
	ul {
		--bs-nav-link-padding-x: 1rem;
		--bs-nav-link-padding-y: 0.5rem;
		--bs-nav-link-font-weight: ;
		--bs-nav-link-color: var(--bs-link-color);
		--bs-nav-link-hover-color: var(--bs-link-hover-color);
		--bs-nav-link-disabled-color: var(--bs-secondary-color);
		display: flex;
		flex-wrap: wrap;
		padding-left: 0;
		margin-bottom: 0;
		list-style: none;
	}
}

.social-button {
  background-repeat: no-repeat;
  background-size: cover;
  width: 40px;
  height: 40px;
}

.facebook-button {
  background-image: url('../images/social-media/facebook.webp');
}
 
.twitter-button {
  background-image: url('../images/social-media/x-twitter.webp');
}
 
.linkedin-button {
  background-image: url('../images/social-media/linkedin.webp');
}

  /* Section Services */
.card {
  box-shadow: 2px 6px 8px 0 rgba(22, 22, 26, 0.18);
  border: none;
}

.slider-item {
  position: relative;
  transition: transform 0.6s ease-in-out;
}
 
  /* Section Réalisations */
.project-view {
  overflow: hidden;
  position: relative;
  img {
    transition: all 0.2s linear;
  }
  &:hover {
    img {
      transform: scale(1.1);
    }
  }
}

.project-overlay {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #212529;
  opacity: 0;
  transition: all 0.2s linear;
  &:hover {
    opacity: 0.7;
  }
}
  
    /* Section Contact (voir pour les Couleurs plus bas) */
#message {
  max-height: 296px;
}

.fs-24 {
  font-size: 1.5rem;
}

.contact-block::after {
  position: absolute;
  background-color: #dddddd;
  content: "";
  bottom: 0;
  left: 12.5%;
  height: 1px;
  width: 75%;
}
 
.contact-block:last-child::after {
  background-color: transparent !important;
}

.legend-color {
  min-width: 24px;
  max-width: 24px;
  height: 24px;
  background-color: rgba(51, 136, 255, 0.3);
  border: 1px solid rgba(51, 136, 255, 0.6);
  border-radius: 1rem;
}

#map {
  height: 500px;
}

.legend {
  background-color: rgba(255, 255, 255, 0.75);
  position: absolute;
  z-index: 800;
  border: 2px solid rgba(0, 0, 0, 0.3);
  border-radius: 4px;
  bottom: 20px;
  left: 10px;
}

.leaflet-touch .leaflet-bar a {
  width: 35px;
  height: 35px;
  line-height: 35px;
}

.form-control {
  &.is-invalid, &.is-valid{
    background-image: none;
  }
}

.form-check-input.is-valid {
  border-color: rgba(0, 0, 0, 0.25);
}

.form-check-input.form-check-color:checked {  /* Couleur primaire ou secondaire selon la couleur des labels */
  background-color: #c67439;
  border-color: #c67439;
}

.form-check-input.form-check-color:focus {   /* Couleur primaire ou secondaire selon le cas */
  border-color: #c67439;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(198, 116, 57, 0.25);  /* opacité plus faible */
}

.btn-call-link {
  width: 48px;
  transition: width 0s, border-radius 0s;
  padding: 6px 11px;
  border-width: 3px;
  border-radius: 50%;
  background-color: #d19061;
  border-color: #d19061;
  color: #fff;
  &.active{
    background-color: #d19061;
    border-color: #d19061;
    color: #fff;
  }
  &:hover, &:focus, &:active {
    background-color: #d19061 !important; /* luminosité HSL diminuée de 10% */
    border-color: #d19061 !important; /* luminosité HSL diminuée de 10% */
    color: #fff !important;
  }
  .phone-number {
    opacity: 0;
  }
}

.agenda-item {
  height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  background-color: white;
  border-radius: 0.375rem;
  box-shadow: 0 0 0 1px rgba(0,0,0,.12);
  &.booked {
    background-color: rgba(198, 116, 57, 0.07) !important;
    box-shadow: rgba(198, 116, 57, 0.8) 0px 0px 0px 1px !important;
    color: #c67439 !important;
    opacity: 0.65;
  }
  &.closed {
    background-color: transparent !important;
    box-shadow: 0 0 0 1px rgba(0,0,0,0.4) !important;
    opacity: 0.65;
    color: #6c757d !important;
  }
}

  /* Footer */
/* Couleur footer générique (si couleurs primaires ou secondaires ne conviennent pas */
.bg-footer {  /* main-footer */
  background-color: #343a40;
}

.bg-footer-dark {  /* sub-footer */
  background-color: #1e2124;
}

.footer-link {  /* Liens réseaux sociaux */
  color: white;
  text-decoration: none;
  &:hover {
    color: rgb(225, 225, 225);
  }
}

#sub-footer a:hover {
  font-weight: 600;
}

/* COULEURS à éditer puis classes à éventuellement modifier dans les vues */
  /* Couleur primaire - 1 couleur du logo, ici #9c2c2c - Peut servir de couleur sombre de titre/texte si assez foncée sinon voir couleur tertiaire */

.text-color-primary {
  color:#c67439;
}

.text-color-primary-light {
  color:#d19061;
}

.bg-color-primary {
  background-color: #c67439 !important;
}

.border-color-primary {
  border-color: #c67439 !important;
}

.bg-color-primary-light {
  background-color: hsl(0, 56%, 89%) !important;  /* Luminosité augmenté à 85-90% */
}

.bg-color-primary-dark {
  background-color: hsl(0, 56%, 25%) !important;  /* Luminosité diminué de 10-15% */
}

.btn-color-primary {
  background-color: #d19061;
  border-color: #d19061;
  color: #fff;
  &.active{
    background-color: #d19061;
    border-color: #d19061;
    color: #fff;
  }
  &:hover, &:focus, &:active {
    background-color:#cb824d !important; /* luminosité HSL diminuée de 10% */
    border-color: #cb824d !important; /* luminosité HSL diminuée de 10% */
    color: #fff !important;
  }
}

.btn-color-primary-outline {
  background-color: transparent;
  border-color: #d19061;
  color: #d19061;
  &:hover, &:focus, &:active {
    background-color: #d19061 !important;
    border-color: #d19061 !important;
    color: #fff !important;
  }
}

.link-color-primary {
  color:#c67439 !important;
  transition: all 0.3s ease-in-out;
  &:hover {
    font-weight: 600;
  }
}

.nav-link.text-color-primary {  /* Alternative "couleur primaire" de titre si besoin */
  border-bottom: 1px solid transparent;
  color: #c67439 !important;
  transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out, border-color 0.3s ease-in-out, font-weight 0.3s ease-in-out;
    &:hover, &.active {
      color: #7e9581 !important;  /* Couleur secondaire au survol */
      border-bottom: 1px solid #9cbd9c;
    }
}

.form-color-primary {
  background-color: rgba(198, 116, 57, 0.025) !important;
  &:focus {
    color: hsla(26, 54%, 5%, 0.87);
    border-color: rgba(198, 116, 57, 0.5);
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(198, 116, 57, 0.25);
  }
}

  /* Couleur secondaire - Ici, 2ème couleur du logo : #343a40 + variantes avec luminosité HSL diminuée de 10% ou opacité plus faible
  (Peut nous servir également de couleur par défaut pour titres/link/footer si assez sombre)
      OU couleur d'accentuation choisie par client voire nous
  */

.text-color-secondary {
  color:#7e9581;
}

.bg-color-secondary {
  background-color: #7e9581 !important;
}
 
.bg-color-secondary-80 {    /* Utile au fond de la carte de la bannière */
  background-color: rgba(185, 198, 187, 0.80) !important;
}
 
.bg-color-secondary-light {
  background-color: hsl(129, 10%, 97%) !important;     /* luminosité HSL augmenté à 85-95% */
}
 
.bg-color-secondary-dark {  /* Par exemple, subfooter */
  background-color: #657b68 !important;   /* luminosité HSL diminuée de 10% */
}

.btn-color-secondary-outline {
  background-color: transparent;
  border-color: #7e9581;
  color: #7e9581;
  &:hover, &:focus, &:active {
    background-color: #778c7a !important;
    border-color: #778c7a !important;
    color: #fff !important;  /* Ajuster selon la couleur du bg */
  }
}

.border-color-secondary {
  border-color: #b9c6bb !important;
}

.btn-color-secondary {
  background-color: #7e9581;
  border-color: #7e9581;
  color: #fff;
  &.active{
    background-color: #7e9581;
    border-color: #7e9581;
    color: #fff;
  }
  &:hover, &:focus, &:active {
    background-color: #778c7a !important;  /* luminosité HSL diminuée de 10% */
    border-color: #778c7a !important;  /* luminosité HSL diminuée de 10% */
    color: #fff !important;
  }
}

.form-color-secondary {
  background-color: rgba(185, 198, 187, 0.05) !important;  /* opacité plus faible */
  &:focus {
    color: #013132;     /* Voir couleur tertiaire */
    border-color: rgba(185, 198, 187, 0.5);  /* opacité plus faible */
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(185, 198, 187, 0.25);  /* opacité plus faible */
  }
}

.nav-link.text-color-secondary {   /* 1ère alternative de "noir" de titre si la couleur secondaire est un gris foncé */
  border-bottom: 1px solid transparent;
  color: #7e9581 !important;
  transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out, border-color 0.3s ease-in-out, font-weight 0.3s ease-in-out;
    &:hover, &.active {
      border-bottom: 1px solid #c67439;
      color: #c67439 !important;  /* Couleur primaire au survol */
    }
}

.link-color-secondary {
  color:#7e9581 !important;
  transition: all 0.3s ease-in-out;
  &:hover {
    font-weight: 600;
  }
}

  /* Couleur tertiaire = éventuelle 3ème couleur différente ou basée par exemple sur la couleur primaire si celle-ci pas assez foncée */
  /* Non utilisée ici dans le modèle */
.text-color-tertiary {
  color:hsl(26, 54%, 5%, 0.87);   /* Exemple ici : primaire à 5-10% de luminosité */
}

.bg-color-tertiary {
  background-color: hsl(0, 56%, 10%);
}

.btn-color-tertiary {
  background-color: #631c1c;
  border-color: #631c1c;
  color: #fff;
  &.disabled {
    background-color: transparent;
    color: #631c1c;
  }
  &.active{
    background-color: #631c1c;
    border-color: #631c1c;
    color: #fff;
  }
  &:hover, &:focus, &:active {
    background-color: #3c1111 !important; /* luminosité HSL diminuée de 10% */
    border-color: #3c1111 !important;  /* luminosité HSL diminuée de 10% */
    color: #fff !important;
  }
}

.nav-link.text-color-tertiary {
  border-bottom: 1px solid transparent;
  color: #140c06de !important;
  transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out, border-color 0.3s ease-in-out, font-weight 0.3s ease-in-out;
  &:hover, &.active {
    border-bottom: 1px solid #c67439;
    color: #c67439 !important;  /* Couleur primaire ou secondaire au survol */
  }
}

.link-color-tertiary {
  border-bottom: 1px solid transparent;
  color:#280b0b !important;
  transition: all 0.3s ease-in-out;
  &:hover {
    border-bottom: 1px solid #c67439;
    color: #c67439 !important;  /* Couleur primaire ou secondaire au survol */
    font-weight: 600;
  }
}
  
@media (min-width: 360px) {
  .project-view {
    width: 336px;
  }
}
  
  
@media (min-width: 400px) {
  .value-description {
    padding: 0 1.5rem;
  }
}

@media (min-width: 472px) {
  .agenda-form {
    max-width: 448px;
  }
}

@media (min-width: 500px) {
  .value-description {
    padding: 0 2.5rem;
  }
}
  
@media(min-width: 576px) {
  .rounded-sm-3{
    border-radius: 0.5rem;
  }

  .top-header {
    height: 37px;
  }

  .review-link-footer {
    margin-right: 3rem;
  }

  .banner-container {
    left: 10%;
  }

  #banner-info {
    width: 410px;
  }
}

@media(max-width: 767px)  {
  .post {
    img {
      float: none !important;
      display: block;
      margin-left: auto !important;
      margin-right: auto !important;
    }
  }
}
  
@media(min-width: 768px)  {
  #banner-info {
    width: 465px;
  }

  .contact-block::after {
    background-color: transparent !important;
  }
 
  .contact-odd:nth-child(even)::before {
    position: absolute;
    background-color: #dddddd;
    content: "";
    bottom: 0;
    left: 0;
    height: 100%;
    width: 1px;
  }

  .legend {
    bottom: 10px;
  }

  .logo-size {
      max-height: 50px;
  }

  .nav-min-height { 
    min-height: 76px;
  }

  .toggler-min-height { 
    min-height: 60px;
  }

  .mt-main { 
    margin-top: 76px;
  }

  .modal-img {
    max-width: 75%;
  }
}
 
@media (max-width: 991px) {
  .aos-img {
      opacity: 1 ; 
      transform : none;
      margin-bottom: 1rem; 
    }
}
  
@media(min-width: 992px) {
  #map {
    height: auto;
  }

  .about-quote {
    &::before, &::after {
      font-size: 3.5rem;
    }
  }
 
  .about-quote::before {
    top: -2rem;
    left: -1.5rem;
  }
 
  .about-quote::after {
    bottom: -2rem;
    right: -1.5rem;
  }
 
  #aos-img {
    transform: translateX(-50%);
    transition: all 1.2s ease-in-out;
    &.aos-animate {
      transform: translateX(0%);
    }
  }

  [data-aos="about-animation"] {
    opacity: 0;
    transform: translateX(100px);
    transition-property: transform, opacity;
    &.aos-animate {
      opacity: 1;
      transform: translateX(0);
    }
  }

  #text-over-left {
      position: absolute;
      left: 0;
      top: 40%;
      opacity: 1 ; 
      transform : none;
  }

  .project-view {
    width: auto;
  }

  .review-link {
    margin-right: 32px;
  }

  [data-aos="post-comment-animation"] {
    opacity: 0;
    transform: translateX(100px);
    transition-property: transform, opacity;
    &.aos-animate {
      opacity: 1;
      transform: translateX(0);
    }
  }
  .post-comment.promoted {
    -webkit-line-clamp: 5;
  }

  .agenda-grid {
    grid-template-columns: repeat(auto-fill, 108px);
  }
  
  .agenda-item {
    height: 108px;
  }
}

@media (min-width: 768px) and (max-width: 1199px) {
  .contact-even:nth-child(even)::before {
    position: absolute;
    background-color: #dddddd;
    content: "";
    bottom: 0;
    left: 0;
    height: 100%;
    width: 1px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .mb-lg_xl-10 {
    margin-bottom: 10rem !important;
  }
} 
  
@media (max-width: 1199px) {
  #banner-img {
    max-width: none;
    width: auto;
    height: 300px;
  }
}
  
@media(min-width: 1200px)  {
  .fs-xl-5 {
    font-size: 1.25rem;
  }

  .logo-size {
      max-height: 55px;
  }

  .img-carousel-mobile  {
    height: auto !important;
    max-width: 100% !important;
  }

  #banner-info {
    width: 550px;
  }

  .nav-min-height {
    min-height: 81px;
  }

  .mt-main {  /* Margin top du main = hauteur header*/
    margin-top: 81px;
  }

  #text-over-left {
      top:auto;
  }

  .modal-img {
    max-width: 45%;
  }

  .post-comment.promoted {
    -webkit-line-clamp: 6;
  }
  
  .contact-even:nth-child(2)::before {
    position: absolute;
    background-color: #dddddd;
    content: "";
    bottom: 0;
    left: 0;
    height: 100%;
    width: 1px;
  }
 
  .contact-even:nth-child(2n+3)::before {
    position: absolute;
    background-color: #dddddd;
    content: "";
    bottom: 0;
    left: 0;
    height: 100%;
    width: 1px;
  }

  .btn-call-link:hover {
    width: 100%;
    border-radius: 2rem;
    transition: width 1s;
    .phone-number {
      opacity: 1;
    }
  }
}
      
@media (min-width: 1400px) {
  .banner-container {
    left: 12%;
  }

  .about-quote {
    &::before, &::after {
      font-size: 3.5rem;  /* à custom */
    }
  }
 
  .about-quote::before {
    top: -0.5rem;
    left: -0.5rem;
  }
 
  .about-quote::after {
    bottom: -0.5rem;
    right: -0.5rem;
  }
}